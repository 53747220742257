@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.socialPicturesContainer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	gap: 2%;
	margin: 20px 0;

	@media #{map.get(v.$media, "desktopS")} {
		grid-template-columns: 336px 3fr 1fr;
		grid-template-rows: 1fr;
		justify-content: start;
		padding-right: 0px;
	}
}

.advertorialContainer {
	grid-column: 1/3;
	grid-row: 2;
	width: 100vw;
	padding: 0 20px;

	@media #{map.get(v.$media, "desktopS")} {
		width: 336px;
		grid-column: 1/2;
		grid-row: 1;
		padding: 0;
	}
}

.defaultAdvertorialContainer {
	@media #{map.get(v.$media, "tablet")} {
		grid-auto-columns: 1fr 1fr;
	}

	@media #{map.get(v.$media, "desktopS")} {
		grid-auto-columns: auto;
	}
}

.heading {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid var(--grey2);

	@media #{map.get(v.$media, "tablet")} {
		border: none;
	}
}

.advertorialImageLink {
	margin: 0 10px 10px 0;
	@media #{map.get(v.$media, "tablet")} {
		float: left;
	}
}

.advertorialImage {
	width: 100%;
	object-fit: contain;

	@media #{map.get(v.$media, "desktopS")} {
		width: 336px;
		object-fit: cover;
	}
}

.socialPictures {
	grid-column: 1/3;
	grid-row: 1;
	margin-bottom: 20px;
	width: 100vw;
	padding: 0 20px;

	@media #{map.get(v.$media, "desktopS")} {
		grid-column: 2/3;
		width: auto;
	}
}

.socialPicturesGrid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;

	img {
		object-fit: cover;
		vertical-align: middle;
		width: calc((100vw - 60px) / 3);
		padding: 0;

		@media #{map.get(v.$media, "desktopS")} {
			width: auto;
		}
	}
}
